import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import pitbullIllustration from "../images/pitbull.svg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`writing`]}
        title="Home"
      />

      <section className="text-center">
        <img
          alt="Pitbull dog"
          className="block w-1/2 mx-auto mb-8"
          src={pitbullIllustration}
        />

        <h2 className="inline-block p-3 mb-4 text-2xl font-bold bg-yellow-400">
          Hey there! Welcome to CJ Echols&apos; site.
        </h2>
      </section>
    </Layout>
  );
}

export default IndexPage;
